<p-table [value]="exposureRec().schedule" id="printExposureTable">
  <ng-template pTemplate="caption">{{ title() }}</ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th style="width: 50px">Week</th>
      <th>Weekly Deposits</th>
      <th>Weekly Commissions</th>
      <th>Weekly Part$</th>
      <th>Weekly Payments</th>
      <th>Weekly Exposure</th>
      <th>Running Exposure</th>
      <th>Running Exp With Comm</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rec>
    <tr>
      <td>{{ rec.weekNum }}</td>
      <td>{{ rec.deposit | number: '1.0-2' }}</td>
      <td>{{ rec.commision | number: '1.0-2' }}</td>
      <td style="font-weight: bold">{{ rec.totDeposit | number: '1.0-2' }}</td>
      <td>{{ rec.netPayment | number: '1.0-2' }}</td>
      <td>{{ rec.weeklyExposure | number: '1.0-2' }}</td>
      <td>{{ rec.exposure | number: '1.0-2' }}</td>
      <td>{{ rec.exposureWithComm | number: '1.0-2' }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr>
      <td></td>
      <td>${{ exposureRec().totDeposit | safeNumber: '1.0-2' }}</td>
      <td>${{ exposureRec().totComm | number: '1.0-2' }}</td>
      <td style="font-weight: bold">${{ exposureRec().totPart | number: '1.0-2' }}</td>
      <td>${{ exposureRec().totPayment | number: '1.0-2' }}</td>
      <td>${{ exposureRec().totExposure | number: '1.0-2' }}</td>
      <td>${{ exposureRec().totExposure | number: '1.0-2' }}</td>
      <td>${{ exposureRec().totExposureWithCom | number: '1.0-2' }}</td>
    </tr>
  </ng-template>
</p-table>

@if (mcaid()) {
  <div class="row mt-3">
    <div class="col-2">
      <button class="btn btn-secondary w-100" type="button" (click)="print()" label="Print"><i class="fa fa-print"></i> Print</button>
    </div>
  </div>
}
